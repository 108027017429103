/*
Author: Insite Media AS
Author URI: https://insitemedia.no
Description: A custom CSS theme for Aquaflow's application.
Version: 0.5

----------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
    - Font
	- Normalize
	- Box sizing
    - Size
# Login
    - Styling
# Layout
    - Header
# Elements
    - Buttons
# Breadcrumbs & Filter
    - Breadcrumbs
    - Filter
# Cards
    -
# Forms
    -
# Components
    - Alarm
        - Selected alarm
    - Status
--------------------------------------------------------------*/

/*------------- Generic -------------*/
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&family=Open+Sans:wght@300;400;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --var-main-color: #ff8200;
    --var-secondary-color: #231F20;
    --var-white: #ffffff;
    --var-green: #8CDD8F;
    --var-red: #DD8C8C;
    --var-yellow: #DADD8C;
    --var-grey: #E2E2E2;
    --var-info:#d1ecf1;
    --var-warning:#fff3cd;
}

html {
    margin: 0;
    padding: 0;
    line-height: 1.15;
	-webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: 100%;
}

body {
	margin: 0;
}

*, *::after, *::before {
    box-sizing: inherit;
}

.container-fluid {
    max-width: 80%;
}

.container-fluid.space {
    margin-bottom: 35px;
}

#page {
    background: whitesmoke;
    padding: 0 0 35px 0;
    min-height: 100vh;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

b,
strong {
	font-weight: bolder;
}

.center {
    text-align: center;
}

small {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

/*------------- Login -------------*/

#loginBackground {
    height: 100vh;
    min-height: 900px;
    background: url('../public/img/bakgrunn.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    position: relative;
    z-index: 0;
}

#loginBackground .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #050F26;
    opacity: 0.7;
    z-index: 10;
}

#loginBackground .container-fluid {
    max-width: 85%;
    margin: 0 auto;
    position: relative;
    z-index: 20;
}

#loginBackground h1 {
    color: var(--var-main-color);
    font-family: "tachyon", sans-serif;
}

.login-card {
    background: var(--var-white);
    border-radius: 4px;
    padding: 47px;
    text-align: center;
}

.login-card h2,
.login-card p {
    color: var(--var-secondary-color);
}

.login-card h2 {
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
}

.login-card p {
    font-size: 1.15rem;
}

.login-card-header {
    padding: 35px 0 0 0;
}

.login-card-content {
    padding: 25px 0 20px 0;
}

.login-card button {
    display: block;
    width: 100%;
    padding: 12px 0;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    transition: all 0.25s;
}

.login-button {
    background: var(--var-secondary-color);
    font-weight: 700;
    color: var(--var-white);
    border: 2px solid var(--var-secondary-color);
    margin-bottom: 10px;
}

.login-button:hover {
    cursor: pointer;
    background: transparent;
    color: var(--var-secondary-color);
}

.register-button {
    color: var(--var-secondary-color);
    font-weight: 400;
    background: var(--var-green);
    border: 2px solid var(--var-green);
    display: block;
    padding: 11px 0;
    border-radius: 4px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
}

.register-button:hover {
    background: transparent;
    cursor: pointer;
}

.login-card-footer {
    padding: 30px 0 30px 0;
}

.login-card-footer p {
    font-size: 0.875rem;
}

.login-card-footer a {
    color: var(--var-secondary-color);
    text-underline-offset: 2px;
    transition: all 0.25s;
}

.login-card-footer a:hover {
    text-underline-offset: 3px;
}

/*------------- Header -------------*/

.header-nav {
    background: var(--var-secondary-color);
    height: 80px;
}

.header-nav .container-fluid {
    max-width: 100%;
    padding: 0 2.5%;
}

.header-nav .custom-navbar {
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 25px;
}

.header-nav .navbar-brand,
.header-nav ul {
    flex: 1;
}



.header-nav .custom-navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    justify-content: flex-end;
    flex-direction: row;
}

.circle-nav {
    background: #000;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    position: relative;
    color: var(--var-white);
}
.circle-nav i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.alarm-number {
    position: absolute;
    top: -1px;
    right: -2px;
    font-size: 0.55rem;
    color: var(--var-secondary-color);
    background: var(--var-main-color);
    border-radius: 100%;
    font-weight: 700;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
}

.header-nav li a {
    font-family: "Poppins", sans-serif;
    font-size: 1.15rem;
    color: var(--var-white);
}

.header-nav li a:hover {
    color: var(--var-main-color);
}

#srcResult {
    position: absolute;
    top: 41;
    left: 0;
    z-index: 10;
    background: var(--var-white);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 100%;
    padding: 0;
    transition: all 0.25s;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    border-left: 1px solid var(--var-secondary-color);
    border-bottom: 1px solid var(--var-secondary-color);
    border-right: 1px solid var(--var-secondary-color);
}

#srcResult p {
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
}

.nav-link.active {
    color: var(--var-main-color) !important;
}

.header-nav li {
    position: relative;
}

.header-nav li.text-link {
    margin-right: 15px;
}

.header-nav li.text-link a {
    font-size: 1rem;
}

.header-nav li #alarm-notification {
    top: -5%;
    position: absolute;
    right: -10px;
    padding: 3px 6px;
    background: var(--var-red);
    color: whitesmoke;
    font-weight: 700;
    border-radius: 100%;
    font-size: 0.75rem;
}

.header-nav input {
    height: 100%;
    width: 100%;
    background: #000 !important;
    padding: 10px 10px 10px 40px;
    box-sizing: border-box;
    font-size: 0.875rem;
    color: var(--var-white);
    border: none;
    background: var(--var-grey);
    border-radius: 7px;
    font-family: "Poppins", sans-serif;
}

#search-wrapper {
    position: relative;
    left: 0;
    top: 0;
    text-align: left;
    flex: 2;
    max-width: 600px;
}

#search-wrapper > i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    font-size: 1rem;
    color: var(--var-grey);
}

.navbar-dark {
    justify-content: space-between !important;
}

#navbarSupportedContent {
    justify-content: flex-end;
}


/*------------- Elements -------------*/

.button {
    border-radius: 4px;
    border: 2px solid;
    padding: 10px 35px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    transition: all 0.25s;
}

.action-delete {
    float: unset;
    border: 2px solid var(--var-red);
    background: transparent;
    margin-top: 20px;
}

.action-delete:hover {
    background: var(--var-red);
}

.action-create,
.action-edit {
    float: right;
    margin-top: 20px;
}

.action-edit {
    border: 2px solid var(--var-secondary-color);
    color: var(--var-secondary-color);
    text-decoration: none;
    margin-right: 10px;
}

.label.success,
.label.warning,
.label.info, 
.label.danger {
    padding: 4px 11px;
    background: var(--var-green);
    border-radius: 4px;
    display: inline-block;
}
.label.warning {
    background: var(--var-warning);
}
.label.info{
    background: var(--var-info);
}
.label.danger {
    background: var(--var-red);
}

#graph-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 4;
}

#graph-modal .overlay {
    z-index: 5;
    background: var(--var-secondary-color);
    opacity: 0.55;
    width: 100%;
    height: 100%;
    position: fixed;
}

#graph-modal .overlay:hover {
    cursor: pointer;
}

#graph-modal .content {
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1200px;
    height: 800px;
    background: var(--var-white);
    padding: 35px;
    border-radius: 4px;
}

.org-list {
    list-style: none;
    margin: 25px 0;
    padding: 0;
}

.org-list li {
    margin: 8px 0;
}

@media screen and (max-width: 1300px) {
    #graph-modal .content {
        width: 90%;
        height: 80vh;
    }
}

.label.danger {
    background: var(--var-red);
}

.action-edit:hover {
    background: var(--var-secondary-color);
    color: var(--var-white);
}

.button:hover {
    cursor: pointer;
}


h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

td, p, span, label, li, dd, dt, th {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #020610;
    font-size: 1.25rem;
}

td.actions {
    text-align: right;
}

.button.secondary {
    background: transparent;
    color: var(--var-secondary-color);
    border-color: var(--var-grey);
    text-decoration: none;
    margin-right: 10px;
}

.button.secondary:hover {
    background: var(--var-grey);
}

.button.success {
    border-color: var(--var-green);
    background: var(--var-green);
    color: var(--var-secondary-color);
    text-decoration: none;
}

.alarm-actions {
    margin-left: auto;
}

button[disabled].button.success:hover {
    background: var(--var-green);
    cursor: unset;
}

button[disabled].button.danger:hover {
    background: var(--var-red);
    cursor: unset;
}

.button.success:hover {
    background: transparent;
}

.button.danger {
    border-color: var(--var-red);
    background: var(--var-red);
}

.button.danger:hover {
    background: transparent;

}

.button.create {
    border-color: var(--var-secondary-color);
    color: var(--var-main-color);
    background: var(--var-secondary-color);
    text-decoration: none;
    transition: all 0.25s;
    float: right;
    display: inline-block;
    margin-top: 20px;
}

a.update {
    border-color: var(--var-secondary-color);
    color: var(--var-main-color);
    background: var(--var-secondary-color);
    text-decoration: none;
    transition: all 0.25s;
}

.button.create:hover {
    cursor: pointer;
    background: transparent;
    color: var(--var-secondary-color);
}

/*------------- Breadcrumbs & Filter -------------*/

.filter-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0;
    align-items: center;
}

#breadcrumbs ul li {
    display: inline;
    font-size: 1.5rem;
    color: var(--var-secondary-color);
}

#breadcrumbs ul li a {
    color: inherit;
    text-decoration: none;
    opacity: 0.75;
    transition: all 0.25s;
}

#breadcrumbs ul li i {
    font-size: 1.15rem;
    margin: 0 10px;
}

#breadcrumbs ul li i.firstCrumb {
    font-size: 2rem;
    margin: 0 5px 0 0;
}

#breadcrumbs ul li a:hover {
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 4px;
}


.time-select {
    border: 1px solid var(--var-secondary-color);
    border-radius: 3px;
    padding: 7px 17px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    flex-grow: 2;
    background: transparent;
}

.time-select.selecting {
    background: var(--var-secondary-color);
    color: var(--var-white);
}


.time-select i {
    margin: 0 10px 0 0;
}

.smaller {
    font-size: 0.75rem;
}

p.smaller {
    margin-bottom: 0;
}

.select-btn {
    background: var(--var-main-color);
    color: var(--var-secondary-color);
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px 15px;
    font-size: 0.9rem;
    border-radius: 3px;
    line-height: 1.4;
    transition: all 0.25s;
    border: 2px solid var(--var-main-color);
}

.pagination-controls {
    margin: 35px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.pagination-controls .page-link {
    border: 1px solid var(--var-secondary-color);
    padding: 6px 10px;
    border-radius: 4px;
    line-height: 16px;
    font-weight: 700;
}

.pagination-controls .page-link.active {
    color: var(--var-main-color);
    background: var(--var-secondary-color);
    border-color: var(--var-secondary-color);
}

.page-view {
    font-size: 0.9rem;
    text-align: right;
    margin-top: 5px;
    margin-right: 15px;
    display: block;
}

.pagination-controls button {
    border: none;
    background: transparent;
}

.select-btn.success-action {
    background: var(--var-green);
    border-color: var(--var-green);
}


.select-btn.secondary-action-btn {
    margin-right: 5px;
    background: transparent;
}


.select-btn.secondary-action-btn:hover {
    background: var(--var-main-color);
}

.select-btn:hover {
    background: transparent;
    color: var(--var-secondary-color);
}

.calendar-start,
.calendar-end,
.calendar-between,
.calendar-start-end{
    color: var(--var-white);
    font-weight: 600;
}

.calendar-start
{
    background: var(--var-secondary-color);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.calendar-between {
    background: var(--var-secondary-color);
    opacity: 0.9;
}
.calendar-start-end{
    background: var(--var-secondary-color);
    border-radius: 25px;
}

.calendar-end {
    background: var(--var-secondary-color);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.time-select.selecting i {
    color: var(--var-white);
}

#custom-date-filter {
    position: relative;
    text-align: right;
}

.date-filter {
    background: whitesmoke;
    padding: 10px 0;
}

.date-ranges {
    list-style: none;
    margin: 0;
    padding: 0;
}

.date-ranges li button {
    display: block;
    width: 100%;
    margin-bottom: -2px;
    border: 2px solid #DEDEDE;
    background: transparent;
    font-size: 1rem;
    padding: 5px 10px;
}

#c-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.date-ranges li:hover button {
    background: var(--var-secondary-color);
    color: var(--var-white);
}

#custom-date-pop {
    position: absolute;
    z-index: 20;
    padding: 25px;
    width: 700px;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    gap: 15px;
    right: 0;
    top: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
}

#custom-date-pop .top-area {
    flex: 1 0 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

#custom-date-pop .top-area button {
    flex: 1;
    border: 2px solid #050F26;
    border-radius: 4px;
    padding: 14px 0;
    line-height: 1;
    transition: all 0.25s;
}

#overlay {
    background: #050F26;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 10;
}

#custom-date-pop .top-area button i {
    font-size: 1.25rem;
    margin-right: 8px;
}

#custom-date-pop .left-area {
    flex: 1 0 75%;
    max-width: calc(75% - 7.5px);
}

#custom-date-pop .left-area ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.left-area ul li {
    margin: 8px 0;
}

.left-area ul li button {
    border: 1px solid #020610;
    background: transparent;
    border-radius: 3px;
    font-size: 0.875rem;
    width: 100%;
    text-align: center;
    transition: all 0.2s;
}

.left-area ul li button:hover {
    background: #020610;
    color: #fff;
    cursor: pointer;
}

.right-area {
    flex: 1 0 25%;
    max-width: calc(25% - 7.5px);
}

.left-area {
    max-width: 350px;
    overflow: scroll;
    display: flex;
}

.left-area::-webkit-scrollbar {
    display: none;
}

.left-area .month-wrapper {
    width: 100%;
    min-width: 100%;
}

.left-area table {
    width: 100%;
    min-width: 100%;
}

.left-area table td,
.left-area table th {
    text-align: center;
}

.left-area table td {
    border-bottom: 1px solid #ccc;
}

.left-area .month-name-col {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.custom-table-wrap {
    display: flex;
    align-items: stretch;
    gap: 0;
    flex-wrap: wrap;
    margin-bottom: 25px;
}
.custom-table-wrap div {
    flex: 1 0 14.4%;
    max-width: 14.4%;
    width: 14.4%;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: -1px;
    margin-right: -1px;
}

.timelabel {
    font-weight: 500;
    margin-bottom: 15px;
    display: block;
}

.custom-table-wrap div:not(.table-header, .not-month):hover {
    cursor: pointer;
    background: var(--var-secondary-color);
    color: var(--var-white);
}

.custom-table-wrap div.selected {
    background: var(--main-color);
    color: var(--text-color);
}

.custom-table-wrap div.not-month {
    background: var(--var-grey);
}

.custom-table-wrap div.selected {
    background: var(--var-secondary-color);
    color: var(--var-white);
    font-weight: 700;
}

.cal-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}

.monthChanger {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.monthChanger #monthName {
    flex: 1 0 50%;
    max-width: 50%;
    width: 50%;
}

.monthChanger h3 {
    margin-bottom: 0;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
}

.monthChanger i {
    color: var(--var-secondary-color);
}

.monthChanger i:hover {
    cursor: pointer;
}

#monthName {
    color: var(--var-secondary-color);
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: capitalize;
}

/*------------- Cards -------------*/

.c-card-content,
.c-card--header {
    padding: 25px;
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.c-card-content {
    height: 100%;
    position: relative;
}

.card {
    border: none !important;
    border-radius: 13px !important;
    height: 100% !important;
}

.card .c-card--header {
    background-color: var(--var-secondary-color);
}

.card .c-card--header h2 {
    color: #E8FFFE;
}

.card .c-card--header h2 i {
    color: #E8FFFE;
}

.card .c-card--header.success h3 i,
.card .c-card--header.error h3 i,
.card .c-card--header.warning h3 i,
.card .c-card--header.info h3 i {
    color: var(--var-secondary-color);
}

.card .c-card--header.success h3,
.card .c-card--header.error h3,
.card .c-card--header.warning h3,
.card .c-card--header.info h3 {
    color: var(--var-secondary-color);
}

.card .c-card--header.success {
    background-color: var(--var-green);
}

.card .c-card--header.error {
    background-color: #EFBCBB;
}

.card .c-card--header.warning {
    background-color: #DADD8C;
}

.card .c-card--header.info {
    background-color: #8CC4DD;
}

.card .c-card--header h3 {
    margin-bottom: 0;
    line-height: 1;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card .c-card--header h3 span {
    font-size: 1.5rem;
}

/*------------- Forms -------------*/

.form-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 25px;
}

.form-wrapper i {
    color: red;
    transition: all 0.2s;
}

.form-wrapper i:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.update-status-form{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.form-bottom-adjust{
    margin-top: auto;
}
.login-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    align-self: center;
    height: 100vh;
    background: #f4f6f7;
}

.login-wrap .button-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-wrap .button-wrap button {
    width: 100%;
    flex: 1 0 100%;
    padding: 11px;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
}


.create-building-form {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
}

.small-text {
    font-size: 0.875rem;
    margin: 5px 0;
}

.triple-form {
    flex: 1 0 calc(33.3333% - 25px);
    max-width: calc(33.3333% - 25px);
    width: calc(33.3333% - 25px);
    padding: 0 25px;
}

.create-building-form .triple-form:nth-child(2) {
    border-left: 1px solid #231F2015;
    border-right: 1px solid #231F2015;
}

.triple-form h3 {
    font-size: 1.5rem;
    border-bottom: 1px solid #231F2015;
    padding: 10px 0;
}

#account-form {
    max-width: 50%;
    margin: 0 auto;
}

.triple-form label,
#account-form label {
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 5px;
}

.triple-form label i,
#account-form label i {
    margin-right: 3px;
}

.triple-form textarea.form-control {
    min-height: 200px;
}

.post-flex {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.post-flex .post-flex-item:first-child {
    flex: 1 0 70%;
}

th.noright,
td.noright {
    text-align: left !important;
}

.triple-form input::placeholder,
textarea.form-control::placeholder {
    opacity: 0.65;
}

.small-text-add,
.small-text-remove {
    margin-right: 0;
    display: block;
    margin: 15px 0;
    background: transparent;
    border: none;
    font-family: "Poppins", sans-serif;
}

.small-text-remove {
    color: var(--var-red);
    font-size: 0.75rem;
    text-align: left !important;
    margin: 5px 0;
    margin-left: 0;
    display: block;
    width: 50%;
}

.small-text-add:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.form-button-wrap {
    display: flex;
    justify-content: space-between;
    margin: 35px 0;
}


/*------------- Components -------------*/

/*------------- Alarm -------------*/
.alarmlist-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.alarm-item {
    flex: 1 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding: 20px 0;
}

.alarm-name {
    flex: 1 0 33%;
    max-width: 33%;
    width: 33%;
}

.alarm-name h5 {
    font-size: 1rem;
    margin-bottom: 5px;
    color: var(--var-secondary-color);
}

.alarm-name h2 {
    color: var(--var-secondary-color);
    font-size: 1.5rem;
    margin-bottom: 0;
}

.alarm-item i {
    font-size: 1.5rem;
    color: var(--var-secondary-color);
}

.alarm-type,
.alarm-time,
.alarm-info {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

.alarm-type {
    flex: 1 0 32%;
    max-width: 32%;
}

.alarm-time {
    flex: 1 0 16%;
    max-width: 16%;
    white-space: wrap;
}

/*------------- Selected Alarm -------------*/

.selectedAlarm {
    display: flex;
    flex-wrap: wrap;
}

.right {
    text-align: right;
}

.selectedAlarm .selected-item {
    flex: 1 0 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    gap: 18%;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #E5E5E5;
}

.selected-item.effort {
    flex-wrap: wrap;
}

.selected-item.effort h5,
.selected-item.effort h3 {
    flex: 1 0 100%;
    max-width: 100%;
}

.selectedAlarm .selected-item:first-child {
    padding-top: 0;
}

.selectedAlarm .selected-item.effort {
    flex-wrap: wrap;
}

.selectedAlarm .selected-item h5 {
    text-transform: uppercase;
    font-size: 1rem;
}

.selectedAlarm .selected-item p {
    font-size: 0.875rem;
    text-align: right;
}

.selectedAlarm .selected-item h3,
.selectedAlarm .selected-item h2 {
    font-size: 1.5rem;
}

.selectedAlarm .selected-item h5,
.selectedAlarm .selected-item h2,
.selectedAlarm .selected-item h3,
.selectedAlarm .selected-item p {
    margin: 0;
}

.fixer-name,
.unit-name,
.building-name {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.selectedAlarm i {
    font-size: 1.5rem;
    color: var(--var-secondary-color);
}

.selected-item-actions {
    display: flex;
    align-items: center;
    margin: 15px 0;
    justify-content: space-between;
}

.selected-item-actions .action-time {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    max-width: 33%;
}

.action-time i {
    font-size: 2rem;
}

/*------------- Alarm Pagination -------------*/

.alarm-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px 0 25px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.alarm-pagination i {
    font-size: 1.25rem;
}

.alarm-pagination span {
    opacity: 0.4;
}

.alarm-pagination span.active {
    opacity: 1;
}

.alarm-pagination span:hover {
    opacity: 1;
    cursor: pointer;
}

.alarm-pagination span.small {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 10px;
}


/*------------- Status -------------*/

.status {
    border-radius: 29px;
    border: 2px solid;
    padding: 6px 0;
    width: 125px;
    text-align: center;
    font-size: 1rem;
    white-space: nowrap;
    text-transform: capitalize;
}

.status.success {
    background: var(--var-green);
    border-color: var(--var-green);
}

.status.danger {
    background: var(--var-red);
    border-color: var(--var-red);
}

.status.warning {
    background: var(--var-yellow);
    border-color: var(--var-yellow);
}

/******* DELETE MODAL *******/

.deleteModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background: var(--var-white);
    z-index: 100;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.deleteHeader {
    padding: 15px 25px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--var-red);
}

.deleteHeader h2 {
    color: var(--var-secondary-color);
    margin-bottom: 0;
    font-size: 1.5rem;
}

.deleteContent {
    padding: 25px;
}

.deleteContent .buttonwrap {
    display: flex;
    justify-content: space-between;
}

.mobMenu {
    display: none;
}


#mobileMenu {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 75%;
    min-height: 100vh;
    background: var(--var-secondary-color);
    z-index: 1000;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    transition: all 0.25s;
}

#mobileMenu.active {
    right: 0;
}

#closeMenu {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--var-white);
    z-index: 500;
}

.infotable {
    width: 100%;
    border-collapse: collapse;
    border-top-left-radius: 25px;
}

.c-bg-dark .infotable th {
    padding: 0 15px 15px 15px;
    font-size: 1rem;
    font-weight: 500;
    background: var(--var-secondary-color);
    color: var(--var-white);
}

.infotable th:last-child,
.infotable td:last-child {
    text-align: right;
}

.infotable tbody td {
    border-top: 1px solid #231F2025;
}

.infotable tr:last-child td {
    border-bottom: 0;
}

.flex-tablebox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.circle-company {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.circle-icon {
    width: 100px;
    height: 100px;
    border: 1px solid var(--var-secondary-color);
    border-radius: 100%;
    margin: 0 auto 25px auto;
    text-align: center;
}

.device-info {
    list-style: none;
    margin: 20px 0;
    padding: 0;
}

.circle-icon i {
    font-size: 3rem;
    color: var(--var-secondary-color);
    line-height: 100px;
}

.c-bg-light .infotable th {
    padding: 0 15px 15px 15px;
    font-size: 1rem;
    font-weight: 500;
    background: var(--var-white);
    color: var(--var-secondary-color);
}

.infotable th:first-child {
    border-top-left-radius: 15px;
}

.infotable td {
    padding: 15px;
    font-size: 1rem;
    font-weight: 400;   
}
.modal-header {
    background-color: var(--var-secondary-color);
    color: white;
}
.modal-header  > button{
    background-color: #6b6b6b;
    color: white;
}
.c-bg-dark .infotable tbody tr:nth-child(odd) {
    background: #000;
}

.c-bg-dark .card {
    background: #000;
}

.c-bg-light .card {
    background: var(--var-white);
}

.c-bg-dark .infotable tfoot tr td {
    background: var(--var-secondary-color);
    color: var(--var-white);
    font-size: 0.75rem;
}

.c-bg-light .infotable tfoot tr td {
    background: var(--var-white);
    color: var(--var-secondary-color);
    font-size: 0.75rem;
}

.infotable tfoot tr td:first-child {
    border-bottom-left-radius: 15px;
}

.infotable tfoot tr td:last-child {
    border-bottom-right-radius: 15px;
}

.c-bg-dark .card h2 {
    color: var(--var-white);
    font-size: 1.5rem;
    margin-bottom: 0;
}

.c-bg-light .card .c-card--header h2 {
    color: var(--var-white);
    font-size: 1.5rem;
    margin-bottom: 0;
}

.c-bg-dark .infotable tbody tr:nth-child(even) {
    background: var(--var-secondary-color);
}

.c-bg-dark .infotable tbody tr td {
    color: var(--var-white);
}

.c-bg-light .infotable tbody tr td {
    color: var(--var-secondary-color);
}

.infotable td a {
    color: inherit;
    text-decoration: none;
}

.infotable th:last-child {
    border-top-right-radius: 15px;
}
.alarm-history-wrapper{
    padding:0;
    margin:0;
}

#mobileMenu ul {
    margin-top: 25px;
}

#mobileMenu ul li.text-link {
    display: block;
    margin: 12px 0;
}

#mobileMenu ul li a {
    font-size: 1.5rem;
}

.mobile-only {
    display: none;
}

.triggered-flex,
.flex-information {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--var-secondary-color);
}

.trig-action,
.trig-when,
.flex-box {
    margin: 10px 0;
}

.trig-action,
.flex-action {
    display: flex;
}

.trig-action a,
.trig-action button {
    flex: 1;
    text-align: center;
    text-decoration: none;
}

.flex-action a,
.flex-action button {
    flex: 0;
    text-align: center;
    text-decoration: none;
}

.flex-box .info-list {
    list-style: none;
    margin: 10px 0;
    padding: 0;
}

.flex-box .info-list li {
    font-size: 1rem;
}

.flex-action.wide a,
.flex-action.wide button {
    flex: 1;
}

@media screen and (max-width: 1200px) {
    .c-bg-light.col-sm-6 {
        flex: 1 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    .text-link {
        display: none;
    }

    .create-building-form {
        flex-wrap: wrap;
    }

    .triple-form {
        flex: 1 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 0 10px;
    }

    .create-building-form .triple-form:nth-child(2) {
        border-left: none;
        border-right: none;
    }

    #search-wrapper {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        z-index: 100;
        background: var(--var-secondary-color);
        padding: 15px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }

    #search-wrapper input {
        width: 100%;
    }

    #search-wrapper i {
        left: 28px;
    }

    .header-nav {
        margin-bottom: 73px;
    }

    #loginBackground {
        text-align: center;
    }

    .mobMenu {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

}

/* SEARCH AND RESULTS STYLING */
.result-header{
    background-color: var(--var-secondary-color);
    padding:10px 15px;
    color: var(--var-white);
}
.result-header h5{
    margin-bottom: 0;
}
.result-line{
    padding:15px 15px;
    font-size: 18px;
}
.result-show-more{
    padding: 8px 15px;
    font-size: 14px;

}
.result-line-link{
    text-decoration: none;
    color: var(--text-color);
}
.result-show-more {
    color: var(--var-main-color);
}
.result-show-more:hover {
    background-color: rgb(243, 243, 243);
}
.result-line:hover {
    background-color: rgb(243, 243, 243);
}
.highlight-wrapper{
    margin:auto !important;
}
.highlight-row{
    font-size: 12px;
    margin: auto;
    color: grey;
}
.highlight-row span {
    font-size: 12px;
    color: grey;
}
.highlight-row span.highlight{
    color: black !important;
}

@media screen and (max-width: 1200px) {
    .highlight-row {
        text-align: left;
        justify-content: flex-start;
    }
}

.search-result-count {
    font-size: 12px;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.large-search > div{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.form-rooms-wrapper{
    margin-left: 6px;
    border-left: 2px solid black;
    padding-left:5px
}
.form-floor-wrapper{
    border-bottom: 1px solid black;
    margin-bottom: 20px;
}
.form-rooms-wrapper .form-control,.room-device-select {
    font-size: 14px;    
}
.form-rooms-wrapper section {
    
    border-bottom: 1px dashed grey;
}
.contact-row label {
    margin: auto;
    display: inline-block;
}
.contact-row .form-check {
    display: inline-block;
}
.contact-alert {
    border-bottom: 1px dashed grey;
    margin-bottom: 1em;

}

.buttons-modal {
    display: flex;
    align-items: center;
    gap: 5px;
}

.buttons-modal button {
    flex: 1;
    display: block;
}

@media screen and (max-width: 1200px) {
    .container-fluid {
        max-width: 97%;
    }

    .c-card-content {
        overflow-x: scroll;
    }

    .c-card-content::-webkit-scrollbar {
        display: none;
    }

    .infotable th {
        white-space: nowrap;
    }

    .highlight-row.text-end {
        text-align: left !important;
        justify-content: flex-start;
    }

    #custom-date-pop {
        max-width: 95vw;
        right: -2.5%;
    }

    #custom-date-pop .top-area {
        flex-wrap: wrap;
    }

    #custom-date-pop .top-area span {
        display: none;
    }

    #custom-date-pop .left-area {
        flex: 1 0 100%;
        max-width: 100%;
    }

    #custom-date-pop .right-area {
        flex: 1 0 100%;
        max-width: 100%;
        width: 1005;
    }
}

